// Packages
import React, { createContext, useEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum'

// Utilities
import { validateCookiesToInitDatadog } from '../../utilities/general'

const DatadogContext = createContext({})

interface props {
  children: JSX.Element
}

export const datadogInitiation = () => {
  if (typeof window !== 'undefined') {
    datadogRum.init({
      applicationId: process.env.GATSBY_DATADOG_RUM_APP_ID || '',
      clientToken: process.env.GATSBY_DATADOG_RUM_CLIENT_TOKEN || '',
      site: 'datadoghq.com',
      service: 'beerhouse-storefront',
      env: process.env.GATSBY_ACTIVE_ENV || '',
      version: process.env.GATSBY_PROJECT_VERSION || '',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackingConsent: 'granted',
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
  }
}

const WithDatadogProvider: React.FC<props> = ({ children }) => {
  useEffect(() => {
    if (validateCookiesToInitDatadog()) datadogInitiation()
  }, [])

  return (
    <DatadogContext.Provider value={{}}>
      {children}
    </DatadogContext.Provider>
  )
}

export default WithDatadogProvider
