// Packages
import React from 'react'
import { Amplify } from 'aws-amplify'
import { CookieStorage } from 'aws-amplify/utils'
import 'aws-amplify/auth/enable-oauth-listener'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'

// Components
import WithDataContextProvider from './src/higherOrderComponents/WithDataContextProvider'
import WithFluxContextProviders from './src/higherOrderComponents/WithFluxContextProviders'
import WithAuthContextProvider from './src/higherOrderComponents/WithAuthContextProvider'
import WithApolloProvider from './src/higherOrderComponents/WithApolloProvider'
import WithDatadogContextProvider from './src/higherOrderComponents/WithDatadogProvider'

import config from './src/data/config'

Amplify.configure(config.amplify)
cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({
  domain: config.amplify.cookieStorage.domain,
  secure: config.amplify.cookieStorage.secure,
  expires: config.amplify.cookieStorage.expires,
  sameSite: config.amplify.cookieStorage.sameSite
}))

export const wrapRootElement = ({ element }) => (
  <WithDatadogContextProvider>
    <WithDataContextProvider>
      <WithApolloProvider>
        <WithFluxContextProviders>
          <WithAuthContextProvider>
            {element}
          </WithAuthContextProvider>
        </WithFluxContextProviders>
      </WithApolloProvider>
    </WithDataContextProvider>
  </WithDatadogContextProvider>
)

// This prevents Gatsby from scrolling the page all the way to the top when navigating through query params (e.g. when navigating the pages of a collection through the '?pagina=X' query param)
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  const { location: { pathname: currentPathname } } = routerProps

  if (prevRouterProps) {
    const { location: { pathname: prevPathname } } = prevRouterProps
    return currentPathname !== prevPathname
  }

  return true
}

export const onServiceWorkerUpdateReady = async args => {
  const permissionResponse = typeof window !== 'undfined' && window.Notification && await window.Notification.requestPermission()
  if (permissionResponse && permissionResponse === 'granted') {
    await args.serviceWorker.showNotification('Website update', {
      body:
        'Our website just got a little bit better. We reloaded the site with the update to ensure a smooth experience for you.'
    })
  }
  typeof window !== 'undefined' && window.location.reload()
}
