// Assets
import {
  emailFilter,
  standardStringFilterWithoutSpace,
  onlyWordsFilter,
  standardStringFilter,
  onlyDigitsFilter
} from '../../utilities/forms'
import * as actionTypes from '../../actions/actionTypes'
import intialState from '../../stores/formStore/initialState'

// Types
import FormStoreTypes from '../../types/FormStoreTypes'
import FluxTypes from '../../types/FluxTypes'

const reducer: (state: FormStoreTypes.FormStoreState, action: FluxTypes.ActionObject) => FormStoreTypes.FormStoreState = (state, action) => {
  let newState = state

  switch (action.type) {

  case actionTypes.SET_LOGIN_EMAIL: {
    const filteredLoginEmail = emailFilter(action.email)
    newState = {
      ...state,
      login: {
        ...state.login,
        email: {
          ...state.login.email,
          value: filteredLoginEmail
        }
      }
    }
    break
  }

  case actionTypes.SET_LOGIN_EMAIL_ERRORS:
    newState = {
      ...state,
      login: {
        ...state.login,
        email: {
          ...state.login.email,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_LOGIN_PASSWORD:
    newState = {
      ...state,
      login: {
        ...state.login,
        password: {
          ...state.login.password,
          value: action.password
        }
      }
    }
    break

  case actionTypes.SET_LOGIN_PASSWORD_ERRORS:
    newState = {
      ...state,
      login: {
        ...state.login,
        password: {
          ...state.login.password,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.UPDATE_LOGIN_KEEP_SESSION:
    newState = {
      ...state,
      login: {
        ...state.login,
        keepSession: {
          ...state.login.keepSession,
          value: action.value
        }
      }
    }
    break
  
  case actionTypes.UPDATE_LOGIN_ACCEPT_POLICY:
    newState = {
      ...state,
      login: {
        ...state.login,
        acceptPolicyNConditions: {
          ...state.login.acceptPolicyNConditions,
          value: action.value,
        },
      },
    }
    break
  
  case actionTypes.UPDATE_LOGIN_ACCEPT_POLICY_ERRORS:
    newState = {
      ...state,
      login: {
        ...state.login,
        acceptPolicyNConditions: {
          ...state.login.acceptPolicyNConditions,
          errors: action.errors
        },
      },
    }
    break

  case actionTypes.SET_RECOVER_PASSWORD_EMAIL: {
    const filteredRecoverPasswordEmail = emailFilter(action.email)
    newState = {
      ...state,
      login: {
        ...state.login,
        recoverPassword: {
          ...state.login.recoverPassword,
          email: {
            ...state.login.recoverPassword.email,
            value: filteredRecoverPasswordEmail
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_RECOVER_PASSWORD_EMAIL_ERROS:
    newState = {
      ...state,
      login: {
        ...state.login,
        recoverPassword: {
          ...state.login.recoverPassword,
          email: {
            ...state.login.recoverPassword.email,
            errors: action.errors
          }
        }
      }
    }
    break


  case actionTypes.SET_LOGIN_INITIAL_STATE:
    newState = {
      ...state,
      login: { ...intialState.login }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_INITIAL_STATE:
    newState = {
      ...state,
      createAccount: { ...intialState.createAccount }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_NAME: {
    const filteredCreateAccountName = onlyWordsFilter(action.name)
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        name: {
          ...state.createAccount.name,
          value: filteredCreateAccountName
        }
      }
    }
    break
  }

  case actionTypes.SET_CREATE_ACCOUNT_NAME_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        name: {
          ...state.createAccount.name,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_LAST_NAME: {
    const filteredCreateAccountLastName = onlyWordsFilter(action.lastName)
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        lastName: {
          ...state.createAccount.lastName,
          value: filteredCreateAccountLastName
        }
      }
    }
    break
  }

  case actionTypes.SET_CREATE_ACCOUNT_LAST_NAME_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        lastName: {
          ...state.createAccount.lastName,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_EMAIL: {
    const filteredCreateAccountEmail = emailFilter(action.email)
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        email: {
          ...state.createAccount.email,
          value: filteredCreateAccountEmail
        }
      }
    }
    break
  }

  case actionTypes.SET_CREATE_ACCOUNT_EMAIL_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        email: {
          ...state.createAccount.email,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_PASSWORD:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        password: {
          ...state.createAccount.password,
          value: action.password
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_PASSWORD_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        password: {
          ...state.createAccount.password,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_CONFIRM_PASSWORD:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        confirmPassword: {
          ...state.createAccount.confirmPassword,
          value: action.confirmPassword
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_CONFIRM_PASSWORD_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        confirmPassword: {
          ...state.createAccount.confirmPassword,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_BIRTHDAY:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        birthday: {
          ...state.createAccount.birthday,
          value: action.birthday
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_BIRTHDAY_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        birthday: {
          ...state.createAccount.birthday,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.UPDATE_CREATE_ACCOUNT_SUBSCRIBE_NEWSLETTER:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        subscribeNewsletter: {
          ...state.createAccount.subscribeNewsletter,
          value: action.value
        }
      }
    }
    break

  case actionTypes.UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        acceptTerms: {
          ...state.createAccount.acceptTerms,
          value: action.value
        }
      }
    }
    break

  case actionTypes.UPDATE_CREATE_ACCOUNT_ACCEPT_TERMS_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        acceptTerms: {
          ...state.createAccount.acceptTerms,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_NAME: {
    const filteredCreateAccountBusinessName = standardStringFilter(action.businessName)
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          businessName: {
            ...state.createAccount.business.businessName,
            value: filteredCreateAccountBusinessName
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_NAME_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          businessName: {
            ...state.createAccount.business.businessName,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_RFC: {
    const filteredRfc = standardStringFilterWithoutSpace(action.businessRfc || '', { maxLength: 16 })
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          rfc: {
            ...state.createAccount.business.rfc,
            value: filteredRfc
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_RFC_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          rfc: {
            ...state.createAccount.business.rfc,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          billingAddress: {
            ...state.createAccount.business.billingAddress,
            value: action.billingAddress
          }
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_BILLING_ADDRESS_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          billingAddress: {
            ...state.createAccount.business.billingAddress,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_SAP: {
    const filteredCreateAccountSap = onlyDigitsFilter(action.businessSap, { maxLength: 15 })
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          sap: {
            ...state.createAccount.business.sap,
            value: filteredCreateAccountSap
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CREATE_ACCOUNT_BUSINESS_SAP_ERRORS:
    newState = {
      ...state,
      createAccount: {
        ...state.createAccount,
        business: {
          ...state.createAccount.business,
          sap: {
            ...state.createAccount.business.sap,
            errors: action.errors
          }
        }
      }
    }
    break

    //  -------- Customer info
  case actionTypes.SET_CUSTOMER_INFO_BIRTHDAY:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        birthday: {
          ...state.customerInfo.birthday,
          value: action.birthday
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_BIRTHDAY_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        birthday: {
          ...state.customerInfo.birthday,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_EMAIL: {
    const filteredCustomerInfoEmail = emailFilter(action.email)
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        email: {
          ...state.customerInfo.email,
          value: filteredCustomerInfoEmail
        }
      }
    }
    break
  }

  case actionTypes.SET_CUSTOMER_INFO_EMAIL_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        email: {
          ...state.customerInfo.email,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_NAME: {
    const filteredCustomerInfoName = onlyWordsFilter(action.name)
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        name: {
          ...state.customerInfo.name,
          value: filteredCustomerInfoName
        }
      }
    }
    break
  }

  case actionTypes.SET_CUSTOMER_INFO_NAME_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        name: {
          ...state.customerInfo.name,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_LAST_NAME: {
    const filteredCustomerInfoLastName = onlyWordsFilter(action.lastName)
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        lastName: {
          ...state.customerInfo.lastName,
          value: filteredCustomerInfoLastName
        }
      }
    }
    break
  }

  case actionTypes.SET_CUSTOMER_INFO_LAST_NAME_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        lastName: {
          ...state.customerInfo.lastName,
          errors: action.errors
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_EMAIL_PLACEHOLDER:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        email: {
          ...state.customerInfo.email,
          placeholder: action.value
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_LAST_NAME_PLACEHOLDER:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        lastName: {
          ...state.customerInfo.lastName,
          placeholder: action.value
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_NAME_PLACEHOLDER:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        name: {
          ...state.customerInfo.name,
          placeholder: action.value
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_NAME: {
    const filteredCustomerInfoBusinessName = standardStringFilter(action.businessName)
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          businessName: {
            ...state.customerInfo.business.businessName,
            value: filteredCustomerInfoBusinessName
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_NAME_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          businessName: {
            ...state.customerInfo.business.businessName,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_RFC: {
    const filteredCustomerInfoRfc = standardStringFilterWithoutSpace(action.businessRfc || '', { maxLength: 16 })
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          rfc: {
            ...state.customerInfo.business.rfc,
            value: filteredCustomerInfoRfc
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_RFC_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          rfc: {
            ...state.customerInfo.business.rfc,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          billingAddress: {
            ...state.customerInfo.business.billingAddress,
            value: action.billingAddress
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_BILLING_ADDRESS_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          billingAddress: {
            ...state.customerInfo.business.billingAddress,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_SAP: {
    const filteredCustomerInfoSap = onlyDigitsFilter(action.businessSap, { maxLength: 15 })
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          sap: {
            ...state.customerInfo.business.sap,
            value: filteredCustomerInfoSap
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CUSTOMER_INFO_BUSINESS_SAP_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        business: {
          ...state.customerInfo.business,
          sap: {
            ...state.customerInfo.business.sap,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        changePassword: {
          ...state.customerInfo.changePassword,
          newPassword: {
            ...state.customerInfo.changePassword.newPassword,
            value: action.newPassword
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_NEW_PASSWORD_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        changePassword: {
          ...state.customerInfo.changePassword,
          newPassword: {
            ...state.customerInfo.changePassword.newPassword,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        changePassword: {
          ...state.customerInfo.changePassword,
          confirmNewPassword: {
            ...state.customerInfo.changePassword.confirmNewPassword,
            value: action.confirmNewPassword
          }
        }
      }
    }
    break

  case actionTypes.SET_CUSTOMER_INFO_CHANGE_PASSWORD_CONFIRM_NEW_PASSWORD_ERRORS:
    newState = {
      ...state,
      customerInfo: {
        ...state.customerInfo,
        changePassword: {
          ...state.customerInfo.changePassword,
          confirmNewPassword: {
            ...state.customerInfo.changePassword.confirmNewPassword,
            errors: action.errors
          }
        }
      }
    }
    break

    //  ------ Shipping address

  case actionTypes.SET_SHIPPING_ADDRESS_NAME: {
    const filteredShippingAddressName = onlyWordsFilter(action.name)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          name: {
            ...state.shipping.address.name,
            value: filteredShippingAddressName
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_NAME_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          name: {
            ...state.shipping.address.name,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_LAST_NAME: {
    const filteredShippingAddressLastName = onlyWordsFilter(action.lastName)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          lastName: {
            ...state.shipping.address.lastName,
            value: filteredShippingAddressLastName
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_LAST_NAME_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          lastName: {
            ...state.shipping.address.lastName,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_STREET: {
    const filteredShippingAddressStreet = standardStringFilter(action.street)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          street: {
            ...state.shipping.address.street,
            value: filteredShippingAddressStreet
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_STREET_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          street: {
            ...state.shipping.address.street,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER: {
    const filteredShippingAddressExteriorNumber = standardStringFilter(action.exteriorNumber)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          exteriorNumber: {
            ...state.shipping.address.exteriorNumber,
            value: filteredShippingAddressExteriorNumber
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_EXTERIOR_NUMBER_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          exteriorNumber: {
            ...state.shipping.address.exteriorNumber,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_INTERIOR_NUMBER: {
    const filteredShippingAddressInteriorNumber = standardStringFilter(action.interiorNumber)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          interiorNumber: {
            ...state.shipping.address.interiorNumber,
            value: filteredShippingAddressInteriorNumber
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_INTERIOR_NUMBER_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          interiorNumber: {
            ...state.shipping.address.interiorNumber,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_ZIP: {
    const filteredShippingAddressZip = onlyDigitsFilter(action.zip, { maxLength: 5 })
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          zip: {
            ...state.shipping.address.zip,
            value: filteredShippingAddressZip
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_ZIP_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          zip: {
            ...state.shipping.address.zip,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_NEIGHBORHOOD: {
    const filteredShippingAddressNeighborhood = standardStringFilter(action.neighborhood)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          neighborhood: {
            ...state.shipping.address.neighborhood,
            value: filteredShippingAddressNeighborhood
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_NEIGHBORHOOD_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          neighborhood: {
            ...state.shipping.address.neighborhood,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_STATE:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          state: {
            ...state.shipping.address.state,
            value: action.state
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_STATE_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          state: {
            ...state.shipping.address.state,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_CITY: {
    const filteredShippingAddressCity = standardStringFilter(action.city)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          city: {
            ...state.shipping.address.city,
            value: filteredShippingAddressCity
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_CITY_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          city: {
            ...state.shipping.address.city,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_MUNICIPALITY: {
    const filteredShippingAddressMunicipality = standardStringFilter(action.municipality)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          municipality: {
            ...state.shipping.address.municipality,
            value: filteredShippingAddressMunicipality
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_MUNICIPALITY_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          municipality: {
            ...state.shipping.address.municipality,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_REFERENCES: {
    const filteredShippingAddressReferences = standardStringFilter(action.references)
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          references: {
            ...state.shipping.address.references,
            value: filteredShippingAddressReferences
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_REFERENCES_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          references: {
            ...state.shipping.address.references,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_SHIPPING_ADDRESS_PHONE: {
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          phone: {
            ...state.shipping.address.phone,
            value: action.phone
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_SHIPPING_ADDRESS_PHONE_ERRORS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          phone: {
            ...state.shipping.address.phone,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.UPDATE_ADDRESS_MAINADDRESS:
    newState = {
      ...state,
      shipping: {
        ...state.shipping,
        address: {
          ...state.shipping.address,
          mainAddress: {
            ...state.shipping.address.mainAddress,
            value: action.value
          }
        }
      }
    }
    break

    //  ------ Credit Cards

  case actionTypes.SET_CARD_NAME: {
    const filteredCardName = onlyWordsFilter(action.name)
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          name: {
            ...state.payment.card.name,
            value: filteredCardName
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CARD_NAME_ERRORS:
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          name: {
            ...state.payment.card.name,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CARD_NUMBER: {
    const filteredCardNumber = onlyDigitsFilter(action.number, { maxLength: 16 })
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          number: {
            ...state.payment.card.number,
            value: filteredCardNumber
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CARD_NUMBER_ERRORS:
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          number: {
            ...state.payment.card.number,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CARD_LIST_MONTHS: {
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          expiryMonth: {
            ...state.payment.card.expiryMonth,
            options: action.months
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CARD_EXPIRY_MONTH: {
    const filteredCardExpiryMonth = onlyDigitsFilter(action.month, { maxLength: 2 })
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          expiryMonth: {
            ...state.payment.card.expiryMonth,
            value: filteredCardExpiryMonth
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CARD_EXPIRY_MONTH_ERRORS:
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          expiryMonth: {
            ...state.payment.card.expiryMonth,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CARD_LIST_YEARS: {
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          expiryYear: {
            ...state.payment.card.expiryYear,
            options: action.years
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CARD_EXPIRY_YEAR: {
    const filteredCardExpiryYear = onlyDigitsFilter(action.year, { maxLength: 4 })
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          expiryYear: {
            ...state.payment.card.expiryYear,
            value: filteredCardExpiryYear
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CARD_EXPIRY_YEAR_ERRORS:
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          expiryYear: {
            ...state.payment.card.expiryYear,
            errors: action.errors
          }
        }
      }
    }
    break

  case actionTypes.SET_CARD_CVV: {
    const filteredCardCvv = onlyDigitsFilter(action.cvv, { maxLength: 4 })
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          cvv: {
            ...state.payment.card.cvv,
            value: filteredCardCvv
          }
        }
      }
    }
    break
  }

  case actionTypes.SET_CARD_CVV_ERRORS:
    newState = {
      ...state,
      payment: {
        ...state.payment,
        card: {
          ...state.payment.card,
          cvv: {
            ...state.payment.card.cvv,
            errors: action.errors
          }
        }
      }
    }
    break

  default:

  }

  return newState
}

export default reducer
