// Assets
import mexicoStates from './mexicoStates'
import tagsDictionary from './tagsDictionary'
import imageProperties from './imageProperties'
import dfDelegations from './dfDelegations'
import customerMutationErrorCodes from './customerMutationErrorCodes'
import filterOptions from './filterOptions'
import landingPageSortingOptions from './landingPageSortingOptions'
import profileModalTitlesAndMessages from './profileModalTitlesAndMessages'

export default {
  mexicoStates,
  tagsDictionary,
  imageProperties,
  dfDelegations,
  customerMutationErrorCodes,
  filterOptions,
  landingPageSortingOptions,
  profileModalTitlesAndMessages
}
