// Types
import ProductTypes from '../../types/ProductTypes'
import CartStoreTypes from '../../types/CartStoreTypes'

// Assets
import { extractIdFromStorefrontId } from '../general'
import { CURRENCY_CODE } from '../../data/constants/gtm'

export const addToCartEvent: (
  quantity: number,
  product: ProductTypes.Product
) => void = (quantity, product) => {
  if (product?.variants[0]) {
    const event = {
      event: 'add_to_cart',
      ecommerce: {
        currency: CURRENCY_CODE,
        addToCart: {
          value: product.variants[0].price * quantity,
          items: [
            {
              discount: 0,
              item_category: product.productType || 'No Category',
              item_id: extractIdFromStorefrontId(product.id) || 'No ID',
              item_name: product.title || 'No Title',
              item_brand: product.vendor || 'No Vendor',
              item_variant: product.variants[0].sku || 'No SKU',
              price: product.variants[0].price,
              quantity: quantity
            }
          ]
        }
      }
    }
  
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
  }
}

export const removeFromCartEvent: (
  quantity: number,
  product: ProductTypes.Product
) => void = (quantity, product) => {
  if (product?.variants[0]) {
    const event = {
      event: 'remove_from_cart',
      ecommerce: {
        currency: CURRENCY_CODE,
        removeFromCart: {
          value: product.variants[0].price * quantity,
          items: [
            {
              discount: 0,
              item_category: product.productType || 'No Category',
              item_id: extractIdFromStorefrontId(product.id) || 'No ID',
              item_name: product.title || 'No Title',
              item_brand: product.vendor || 'No Vendor',
              item_variant: product.variants[0].sku || 'No SKU',
              price: product.variants[0].price,
              quantity: quantity
            }
          ]
        }
      }
    }
  
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
  }
}

export const analyticsEvent: (category: string, action: string, label: string) => void = (category, action, label) => {
  const event = {
    event: 'analyticsEvent',
    event_info: {
      category: category,
      action: action,
      label: label
    }
  }

  typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
}

export const signupEvent: (category: string, id: string) => void = (category, id) => {
  const event = {
    event: 'signupEvent',
    event_info: {
      category: category,
      action: 'Click',
      signup_id: id
    }
  }

  typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
}

type PushPageViewEventProps = (
  type: string,
  customer?: { id: string, totalPerOrder: number[] | null },
  promotions?: any[],
  impressions?: any[],
  product?: ProductTypes.Product,
  brand?: string,
  siteSection?: string,
  pageName?: string,
  path?: string
) => void

export const pushPageViewEvent: PushPageViewEventProps = (
  type,
  customer,
  promotions,
  impressions,
  product,
  brand,
  siteSection,
  pageName,
  path
) => {
  let event: any = {}
  const lifetimeValue = customer && customer.totalPerOrder && customer.totalPerOrder.reduce((lifetimeValue, total) => lifetimeValue + total, 0)
  event = {
    page: {
      type: type,
      brand: brand || 'No brand',
      pageName: pageName || 'No page name',
      pageURL: path || 'No page URL',
      siteSection: siteSection || 'No page site section'
    },
    visitor: {
      id: customer && customer.id ? extractIdFromStorefrontId(customer.id) : '',
      existing: customer && customer.id ? 'returning visitor' : 'new visitor',
      customer: lifetimeValue ? 'returning purchaser' : 'new purchaser',
      lifetimeValue: lifetimeValue || ''
    },
    ecommerce: {
      detail: {}
    }
  }

  if (promotions) {
    event.ecommerce.promoView = { promotions }
  }

  if (impressions) {
    event.ecommerce.impressions = impressions
  }

  if (product) {
    event.ecommerce.detail = {
      products: [
        {
          id: extractIdFromStorefrontId(product.id),
          name: product.title,
          brand: product.vendor,
          category: product.productType,
          variant: product.variants[0].title,
          variantId: extractIdFromStorefrontId(product.variants[0].id),
          variantSku: product.variants[0].sku,
          price: product.variants[0].price,
          coupon: ''
        }
      ]
    }
  }

  typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
}

export const viewItemEvent: (product: ProductTypes.Product) => void = (product) => {
  if (product?.variants[0]) {
    const event = {
      event: 'view_item',
      ecommerce: {
        currency: CURRENCY_CODE,
        viewItem: {
          value: product.variants[0].price,
          items: [
            {
              discount: 0,
              item_category: product.productType || 'No Category',
              item_id: extractIdFromStorefrontId(product.id) || 'No ID',
              item_name: product.title || 'No Title',
              item_brand: product.vendor || 'No Vendor',
              item_variant: product.variants[0].sku || 'No SKU',
              price: product.variants[0].price,
            }
          ]
        }
      }
    }
  
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
  }
}

export const viewItemListEvent: (props: ProductTypes.ViewItemListEventProps) => void = ({
  listId,
  listName,
  items
}) => {
  const event = {
    event: 'view_item_list',
    currency: CURRENCY_CODE,
    ecommerce: {
      viewItemList: {
        item_list_id: listId,
        item_list_name: listName,
        items: items.map((item) => {
          return {
            item_category: item.productType || 'No Category',
            item_id: item.id || 'No ID',
            item_name: item.title || 'No Title',
            item_brand: item.vendor || 'No Vendor',
            item_variant: item.variants[0]?.sku || 'No SKU',
            price: item.variants[0].price,
          }
        })
      }
    }
  }

  typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
}

export const loginEvent: (method: string) => void = (method) => {
  if (!method) return
  const event = {
    event: 'login',
    ecommerce: {
      login: {
        method
      }
    }
  }

  typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
}

export const beginCheckoutEvent: (items: CartStoreTypes.Item[]) => void = (items) => {
  if (items.length === 0) return
  const value = items.reduce((total, item) => total + item.variant.price * item.quantity, 0)
  const event = {
    event: 'begin_checkout',
    currency: CURRENCY_CODE,
    ecommerce: {
      beginCheckout: {
        value,
        items: items.map((item) => {
          return {
            item_category: item.variant.product.productType || 'No Category',
            item_id: item.variant.product.id || 'No ID',
            item_name: item.variant.product.title || 'No Title',
            item_brand: item.variant.product.vendor || 'No Vendor',
            item_variant: item.variant.sku || 'No SKU',
            price: item.variant.price,
            quantity: item.quantity
          }
        })
      }
    }
  }

  typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push(event)
}
